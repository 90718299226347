button, input[type="submit"], input[type="reset"] {


	border: none;


	

}




a {
    all: unset;
}


button:hover {
  background-color: rgb(48, 47, 45) ;
  cursor: pointer;
}


.App {
  text-align: center;
  
}
body {
  background: rgb(242, 217, 195);
  overscroll-behavior-y: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


/* @media (min-width: 705px) { */
.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Header */
.Header{
  display: flex;
  height: 80px;
  flex-direction: row;
  
  justify-content: space-between;
  align-content:center;
  align-items: center;
  justify-self: flex-end;
  min-height: 80px;
  /* align-self: flex-end; */
  
  border-bottom: 1px solid rgb(45, 45, 61);

}

/* Within Header */

.buttonColor{
  background-color: rgb(4, 0, 5);
  border-radius: 5px;
  padding: 8px;
  color: white;
  font-size: 18px;
  padding-left: 15px;
  padding-right: 15px;
  transition: all .2s ease-in-out!important;
  
  cursor: pointer;
  

}
.buttonColor:hover{
  background-color: rgb(4, 0, 5) !important;
}

.AppName{

  justify-self: flex-start;
  margin-left: 50px;
  color: rgb(111, 110, 132);
  display: flex;
  height: 48px;
  flex-direction: row;
  
  justify-content: flex-end;
  align-content:center;
  align-items: center;
  
}


.ImageLogo {
  width: 100px;
  
}

.MenuOptionsFlexBox {
  margin-right: 50px;
  display: flex;
  flex-direction: row;
  align-content:center;
  align-items: center;
  justify-content: flex-end;
  
}

.FooterFlexBox {
  margin-right: 50px;
  display: flex;
  flex-direction: row;
  align-content:center;
  align-items: center;
  justify-content: flex-end;
  
}
.MenuOptionsFlexBoxInside{
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  align-content:center;
  align-items: center;
  justify-content: flex-end;
  border-right: 5px solid #e07583;

}

.MenuOptions {
  padding-inline: 10px;

  margin-right: 15px;
  font-weight: 400;
  font-size: 18px;
  transition: all .15s ease-in-out!important;
  cursor: pointer;
}
.MenuOptions:hover {
  color:rgb(247, 247, 247);
  background-color: rgb(18, 18, 26);
  border-radius: 5px;
  
  padding-top: 8px;
  padding-bottom: 8px;

}

.ShowOptions {
  padding-inline: 10px;
  border-radius: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-right: 15px;
  font-weight: 400;
  font-size: 18px;
  background-color: mistyrose;
  transition: all .15s ease-in-out!important;
}

.ShowOptions:hover {
  color:rgb(247, 247, 247);
  background-color: rgb(18, 18, 26);
  cursor: pointer;
}

.FlexBoxLearn:hover {
  
}

.FlexIndividualCollection:hover {
  cursor: pointer;
}

.RealLogo{
  cursor: pointer;
}

.FlexBox{
  display: flex;
  flex-direction: row;
  align-content:center;
  align-items: center;
  text-align: left;
  padding-left: 60px;
  padding-right: 60px;
  margin-top: 20px;
  padding-bottom: 20px;
  flex-wrap: wrap;
  border-bottom: 1px solid rgb(45, 45, 61);
  justify-content: space-between;
  
}
.FlexBoxMiddleImage{
  display: flex;
  flex-direction: row;
  align-content:center;
  align-items: center;
  text-align: left;
  padding-left: 60px;
  padding-right: 60px;
  margin-top: 20px;
  padding-bottom: 20px;
  flex-wrap: wrap;
  border-bottom: 1px solid rgb(45, 45, 61);
  justify-content: space-between;
}

.UpperBodyText{
  font-size: 38px;
  text-align: left; 
}

.UpperBodyImage {
  
}

/* CollectionStartsHere */
.FlexBoxMarkets{
  display: flex;
  flex-direction: row;
  align-content:center;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding:18px;
}
.AllCollectionFlexBox {
  display: flex;
  max-width: 1380px;
  flex-direction: row;
  align-content:center;
  align-items: left;
  overflow-y: none;
  

  /* padding-left: 60px;
  padding-right: 60px; */
  margin-top: 20px;
  padding-bottom: 20px;
  flex-wrap: wrap;
  justify-content: center;

}

.AllProfileCollectionFlexBox{
  display: flex;
  width: 1380px;
  flex-direction: row;
  align-content:left;
  align-items: left;
  overflow-y: scroll;
  border-top: 1px solid rgb(45, 45, 61);
  

  /* padding-left: 60px;
  padding-right: 60px; */
  padding-top:18px;
  padding-bottom: 18px;
  flex-wrap: wrap;
  justify-content: center;
}

.ArtLarge{
  width: 500px;
  height:500px; 
}

.ArtSmall{
  width: 240px;
  height:240px; 
}

.p5Canvas{
  width: inherit !important;
  height: inherit !important;
}

.FlexIndividualCollection{
  display: flex;
  flex-direction: column;
  align-content:center;
  align-items: center;
  text-align: left;
  padding: 0px 0px 20px;
  margin-top: 20px;
  padding-bottom: 20px;
  flex-wrap: wrap;
  border: 1px solid rgb(45, 45, 61);
  margin: 5px;

  justify-content: space-between;
  background-color: peachpuff;
  border-radius: 3px;
  height: 480px
;
}

.FlexIndividualCollectionMainPage{
  display: flex;
  flex-direction: column;
  align-content:center;
  align-items: center;
  text-align: left;
  padding: 0px 0px 20px;
  margin-top: 20px;
  padding-bottom: 20px;
  flex-wrap: wrap;
  border: 1px solid rgb(45, 45, 61);
  margin: 5px;

  justify-content: space-between;
  background-color: peachpuff;
  border-radius: 3px;
  height: 480px
;
}

.Border{
  border-top: 1px solid rgb(45, 45, 61) !important
}

svg .rainbowFill {
  fill: black;
  transition: fill .5s ease;
}

.socialIcon {

  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}


.Menu2{
  flex: 1;
  display: flex;
  flex-direction: column;
 
  text-align: left;
  
  justify-content: space-between;
}
.FooterProps{
  
  display: flex;
  min-height: 80px;
  flex-direction: row;
  
  justify-content: space-between;
  align-content:center;
  align-items: center;
  justify-self: flex-end;

  border-top: 1px solid rgb(45, 45, 61);


}

.Menu1{
  height: 100vh;
  display: flex;
    flex-direction: column;
    flex: 1;
}

.MarginRight{
  margin-right: 50px;
}

.ImageCss{
    flex: 1 1 0%;
    stroke-dasharray: 500;
    max-height: 200px;
    min-width: 200px;
}
.TopImageDiv{
  height: 200px;
}
.FlexBoxMidText{
  display: flex;
  flex-direction: row;
  align-content:center;
  align-items: center;
  text-align: left;
  padding-left: 60px;
  padding-right: 60px;
  margin-top: 20px;
  padding-bottom: 20px;
  flex-wrap: wrap;
  border-bottom: 1px solid rgb(45, 45, 61);
  justify-content: space-between;
}

.FlexBoxLearn{
  
  display: flex;
  max-width: 1260px;
  flex-direction: column;
  align-content:center;
  align-items: center;
  text-align: left;
  padding-left: 60px;
  padding-right: 60px;
  margin-top: 20px;
  padding-bottom: 20px;
  flex-wrap: wrap;
  border-bottom: 1px solid rgb(45, 45, 61);
  justify-content: space-between;
  
}

.FlexBoxUser{
  display: flex;
  max-width: 1260px;
  flex-direction: column;
  align-content:center;
  align-items: center;
  text-align: left;
  padding-left: 60px;
  padding-right: 60px;
  margin-top: 20px;
  padding-bottom: 20px;
  flex-wrap: wrap;
  border-bottom: 1px solid rgb(45, 45, 61);
  justify-content: space-between;
}

.FlexBoxColum{
  display: flex;
  flex-direction: column;
  flex: 0 1;
  border-right: 1px solid rgb(45, 45, 61);
  
}
.IndividualText{
  display: flex;
  width: 200px;
  flex-direction: row;
  justify-content: space-between;
}

.UserMarketImg{
  
  width: 240px;
  height:240px;
}

.SubTextOfCollection{
  display: flex;
  flex-direction: column;
  align-content: left;
  justify-content: center;
  text-align: center;
  height: 80px;
  padding: 20px;
  justify-content: space-between;

  } 


  .OctavasLargeProfileRender {
    display: flex;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border: 1px solid rgb(45, 45, 61);
    margin: 5px;
    background-color: peachpuff;
    border-radius: 3px;
    height: 500px;
    width: 500px;
    flex-direction: column;
  }
@media (max-width: 404px) {
  .AppName{
    margin-left: 0px;
  }
  .MenuOptionsFlexBox{
    margin-right: 0px;
    
  }
  .Header{justify-content: center;}
  .MenuOptions{
    margin-right: 0;
  }

}

@media (max-width: 526px) {
 
  .MenuOptions{
    margin-right: 0;
  }

  .MenuOptionsFlexBoxInside{
    margin-right:10px;
    flex-direction: column;
  }
  .FlexBoxMidText{
    justify-content: space-around;
  }
}

@media (max-width: 630px) {
  .Menu2{flex-direction: column !important}
  .FlexBoxUser{justify-content: center !important;
  flex-direction: row !important; }
  .AllProfileCollectionFlexBox{
    max-width: 1380px !important;
    width: initial;
  }
}


@media (max-width: 895px) {
  .FlexBox{
    flex-direction: column;
    text-align: center;
  }
  .MarginRight{
    margin-right: 0px;
  }
  .ImageCss{
    width:370px
  }
  .TopImageDiv{
    width:370px;
    height:82.22px;
  }
  .DisapearingText{
    display: none;
  }
}

@media (max-width: 940px) {
  /* .FlexBoxColum{
    height:300px !important;
    width: 300px  !important;
  } */
  .ArtLarge{
    height:360px !important;
    width:360px !important;
  }
  .OctavasLargeProfileRender{
    height:360px;
    width:360px;
  }
  .FlexIndividualCollectionMainPage{
    width: 360px !important;
    height:200px !important;
  }
  .FlexBoxMiddleImage {
    flex-direction: column;
    text-align: center;
}
}

@media (min-width: 1380px) { 

  .Menu1{
    display: flex;
    flex-direction: column;
    flex: 1;

    align-items: center;
  }

  .Header{
    display: flex;
    width: 1380px;
    flex-direction: row;
    
    
    justify-content: space-between;
    align-content:center;
    align-items: center;
    justify-self: flex-end;
    /* align-self: flex-end; */
    
    border-bottom: 1px solid rgb(45, 45, 61);
  
  }

  .Menu2{
    width: 1380px;
  }

  .FooterProps{
    
    width: 1380px;
  
    display: flex;
    height: 80px;
    flex-direction: row;
    
    justify-content: space-between;
    /* align-content:center; */
    /* align-items: center; */
    /* justify-self: flex-end; */
  
    border-top: 1px solid rgb(45, 45, 61);
  }

 

  



}